<template>
  <div class="container">
      <br />
      <div class="box">
                         <div @click="$router.push('/')" style='text-align:left; cursor:pointer; '> <i  style=" " class="fas fa-chevron-circle-left"></i> Back</div>

<h5>Login / Sign-Up </h5>
<hr />
          <div id="firebaseui-auth-container"></div>
  </div>
  </div>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
export default {
  name: 'Home',
  components: {
  
  },
  mounted: async function (){


     var uiConfig = {
        signInSuccessUrl: '/Dashboard',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
 
          firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: 'https://app.seasoncast.com/info/TermsOfService.html',
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
          window.location.assign('https://app.termly.io/document/privacy-policy/76905ecb-92bd-4929-90a5-5983694a33d4');
        }
      };

      // Initialize the FirebaseUI Widget using Firebase.
      var ui = new firebaseui.auth.AuthUI(firebase.auth());
      // The start method will wait until the DOM is loaded.
      ui.start('#firebaseui-auth-container', uiConfig);
  },
  methods:{
    socialLogin: async function(){
      var provider = new firebase.auth.GoogleAuthProvider();

  firebase.auth()
  .signInWithPopup(provider)
  .then((result) => {
    /** @type {firebase.auth.OAuthCredential} */
    //var credential = result.credential;

    // This gives you a Google Access Token. You can use it to access the Google API.
    //var token = credential.accessToken;
    // The signed-in user info.
   // var user = result.user;
      console.log(result)
    // ...
  }).catch(() => {
    // Handle Errors here.
   // var errorCode = error.code;
   // var errorMessage = error.message;
    // The email of the user's account used.
   // var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
   // var credential = error.credential;
    // ...
  });

    },
    
  }
}

</script>

<style scoped>
.box{
    border-radius: 1em;
    background-color: white;
    padding: 1em;
   
}
</style>